import React, { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Asterix from '@components/common/Asterix';
import FieldError from '@components/common/FieldError';
import useFieldState from '@components/hooks/useFieldState';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@supertakst/ui-common';

type Alternative = {
  value: string;
  label: string;
};

type Props = {
  id: string;
  header?: string;
  alternatives: Alternative[];
  allowFreetext?: boolean;
  className?: string;
};

const Select = ({ id, header, alternatives, allowFreetext = false, className }: Props) => {
  const { watch, setValue, getValues } = useFormContext();
  const [error, touched] = useFieldState(id);
  const [showTextField, setShowTextfield] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('');

  const dropdownValue = watch(id);
  const values = alternatives.map((alternative) => `${alternative.value}|${alternative.label}`);
  const valueIsFromDropdown = values.includes(dropdownValue);
  const setValueOptions = { shouldValidate: true, shouldTouch: true, shouldDirty: true };

  useEffect(() => {
    if (dropdownValue === undefined) {
      setValue(id, null, { shouldValidate: true });
    }
    if (dropdownValue && valueIsFromDropdown === false) {
      if (allowFreetext) {
        setShowTextfield(true);
        setTextValue(dropdownValue.split('|')[1] ?? dropdownValue);
      }
      setValue(id, dropdownValue, { shouldValidate: true });
    }
  }, []);

  const handleSelectChange = (value: any) => {
    if (value === 'annet|Annet') {
      setValue(id, '', setValueOptions);
      setTextValue('');
      setShowTextfield(true);
    } else {
      setValue(id, value, setValueOptions);
      setShowTextfield(false);
    }
  };

  const handleTextinputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setTextValue(value);
    setValue(id, `annet|${value}`, setValueOptions);
  };

  const value = showTextField ? 'annet|Annet' : getValues(id);

  return (
    <div className={className}>
      <div
        className={twMerge(allowFreetext ? 'grid grid-cols-2 gap-2 items-end' : 'w-full')}
        data-test-type="select"
      >
        <Listbox value={value} onChange={handleSelectChange}>
          {({ open }) => (
            <div>
              <Listbox.Label className="text-base font-normal text-color-supertakst-900 inline-block w-full">
                <span className="mb-2.5 inline-block">
                  {header && (
                    <>
                      {header}
                      {error && <Asterix />}
                    </>
                  )}
                </span>
              </Listbox.Label>
              <div className="relative">
                <Listbox.Button className="relative w-full cursor-default appearance-none rounded h-14 border border-[#303030]/20 text-supertakst-blue-900 bg-supertakst-gray-50 py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="block text-base truncate">{value && value.split('|')[1]}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {alternatives.map(({ value, label }) => {
                      const combinedValue = `${value}|${label}`;
                      const alternativeKey = `${id}-${value}|${label}`;
                      return (
                        <Listbox.Option
                          key={alternativeKey}
                          onChange={handleSelectChange}
                          className={({ active }) =>
                            twMerge(
                              active && 'bg-supertakst-gray-50',
                              'relative cursor-default select-none py-6 pl-3 pr-9 select-option'
                            )
                          }
                          value={combinedValue}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={twMerge(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block text-base truncate'
                                )}
                              >
                                {label}
                              </span>

                              {selected ? (
                                <span
                                  className={twMerge(
                                    active && 'bg-supertakst-gray-50',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      );
                    })}
                    {allowFreetext && (
                      <Listbox.Option
                        value="annet|Annet"
                        onChange={handleSelectChange}
                        className={({ active }) =>
                          twMerge(
                            active && 'bg-supertakst-gray-50',
                            'relative cursor-default select-none py-6 pl-3 pr-9 select-option'
                          )
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={twMerge(
                                selected ? 'font-semibold' : 'font-normal',
                                'block text-base truncate'
                              )}
                            >
                              Annet
                            </span>
                            {selected ? (
                              <span
                                className={twMerge(
                                  active && 'bg-supertakst-gray-50',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>
        {showTextField && (
          <input
            type="text"
            value={textValue}
            onChange={handleTextinputChange}
            placeholder="Vennligst spesifiser..."
            className="w-full text-base text-supertakst-blue-900 bg-supertakst-gray-50 appearance-none border border-[#303030]/20 rounded h-14 px-5 leading-tight focus:outline-none focus:shadow-outline select-text-annet"
          />
        )}
      </div>
      {touched && error && <FieldError error={error} />}
    </div>
  );
};

export default Select;
