import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Textarea from '@components/common/atoms/Textarea';
import BoolInput from '@components/common/CheckBoolean/BoolInput';
import CheckHeader from '@components/common/CheckHeader';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from '@components/common/CheckCard';
import Phrases from '@components/common/Phrases/v2';
import Tooltip from '@components/common/Tooltip';

type PhraseTarget = {
  id: string;
  header: string;
  tg?: string;
};

type Props = {
  id: string;
  header: string;
  hideComment?: boolean;
  phrasesTarget?: PhraseTarget;
};

const CheckBoolean = ({
  id,
  header,
  hideComment = true,
  phrasesTarget = {
    header: 'Oppsummering',
    id: 'totalvurdering.value',
    tg: 'totalvurdering.tg',
  },
}: Props) => {
  const boolName = `${id}.value`;
  const commentName = `${id}.comment`;
  const { control, getValues } = useFormContext();

  hideComment = hideComment === true || typeof getValues(boolName) !== 'boolean';

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <Controller
          name={boolName}
          control={control}
          render={({ field: { onChange, onBlur, ref, name, value } }) => {
            return (
              <BoolInput
                onChange={onChange}
                onBlur={onBlur}
                id={name}
                inputRef={ref}
                value={value}
              />
            );
          }}
        />
        {!hideComment && (
          <>
            <Textarea className="mt-6" header="Vurdering og beskrivelse" id={commentName} />
          </>
        )}
      </CheckCardContent>
      <Phrases
        id={id}
        targetId={phrasesTarget.id}
        targetHeader={phrasesTarget.header}
        tgId={phrasesTarget.tg}
        checkHeader={header}
      />
    </CheckCardItem>
  );
};

export default CheckBoolean;
