import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Textarea from '@components/common/atoms/Textarea';
import CheckHeader from '@components/common/CheckHeader';

import TextInput from '../TextInput';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from '@components/common/CheckCard';
import Phrases from '@components/common/Phrases/v2';
import Tooltip from '@components/common/Tooltip';

export type Alternative = {
  value: string;
  label: string;
};

type PhraseTarget = {
  id: string;
  header: string;
  tg?: string;
};

type Props = {
  id: string;
  header: string;
  alternatives: Alternative[];
  hideComment?: boolean;
  disableUncheck?: boolean;
  phrasesTarget?: PhraseTarget;
};

const CheckText = ({
  id,
  header,
  alternatives,
  hideComment = true,
  disableUncheck = false,
  phrasesTarget = {
    header: 'Oppsummering',
    id: 'totalvurdering.value',
    tg: 'totalvurdering.tg',
  },
}: Props) => {
  const valueName = `${id}.value`;
  const commentName = `${id}.comment`;
  const { control, getValues } = useFormContext();

  hideComment = hideComment === true || !!getValues(valueName) === false;

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>

      <CheckCardContent>
        <Controller
          name={valueName}
          control={control}
          render={({ field: { onChange, onBlur, ref, name, value } }) => {
            return (
              <TextInput
                onChange={onChange}
                onBlur={onBlur}
                id={name}
                inputRef={ref}
                value={value}
                disableUncheck={disableUncheck}
                alternatives={alternatives}
              />
            );
          }}
        />
        {!hideComment && (
          <>
            <Textarea className="mt-6" header="Vurdering og beskrivelse" id={commentName} />
          </>
        )}
      </CheckCardContent>
      <Phrases
        id={id}
        targetId={phrasesTarget.id}
        targetHeader={phrasesTarget.header}
        tgId={phrasesTarget.tg}
        checkHeader={header}
      />
    </CheckCardItem>
  );
};

export default CheckText;
