import React from 'react';
import CheckHeader from '@components/common/CheckHeader';
import Input from '@components/common/atoms/Input';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import Tooltip from '@components/common/Tooltip';
import Textarea from '@components/common/atoms/Textarea';

type Props = {
  id: string;
  header: string;
  type?: string;
  format?: string;
  hideComment?: boolean;
  commentHeader?: string;
};

const SingleInput = ({
  id,
  header,
  type = 'text',
  format,
  hideComment = true,
  commentHeader = 'Beskrivelse',
}: Props) => {
  const valueName = `${id}.value`;
  const commentName = `${id}.comment`;
  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <Input id={valueName} type={type} format={format} />
        {!hideComment && <Textarea className="mt-6" header={commentHeader} id={commentName} />}
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default SingleInput;
