import React from 'react';
import Textarea from '@components/common/atoms/Textarea';
import CheckHeader from '@components/common/CheckHeader';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from '@components/common/CheckCard';
import Phrases from '@components/common/Phrases/v2';
import Tooltip from '@components/common/Tooltip';

type PhraseTarget = {
  id: string;
  header: string;
  tg?: string;
};

type Props = {
  id: string;
  header: string;
  phrasesTarget?: PhraseTarget;
};

const SingleTextarea = ({
  id,
  header,
  phrasesTarget = {
    header: 'Oppsummering',
    id: 'totalvurdering.value',
    tg: undefined,
  },
}: Props) => {
  const valueName = `${id}.value`;

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <Textarea className="mt-6" id={valueName} />
      </CheckCardContent>
      <Phrases
        id={id}
        targetId={phrasesTarget.id}
        targetHeader={phrasesTarget.header}
        tgId={phrasesTarget.tg}
        checkHeader={header}
      />
    </CheckCardItem>
  );
};

export default SingleTextarea;
